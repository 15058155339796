<template>
  <section :class="isFirstConfig ? 'email-form-section' : ''">
    <div :class="isFirstConfig ? 'w-box' : ''">
      <a-form :form="form" @submit="handleSubmit">
        <aRow :gutter="20">
          <aCol :span="24">
            <h2><a-icon type="mail" /> Configurar cliente de email</h2>
          </aCol>
          <aCol :span="12">
            <HayaTextField
              label="E-mail/Username"
              placeholder="Ex: seuemail@voesimples.com..."
              fieldKey="mail_client_email"
              :required="true"
              :form="form"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="12">
            <HayaPasswordField
              label="Senha"
              placeholder="Escreva..."
              fieldKey="mail_client_password"
              :required="true"
              :form="form"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="12">
            <HayaTextField
              label="Servidor de entrada"
              placeholder="Ex: mailer.viajar..."
              fieldKey="mail_client_server"
              :required="true"
              :form="form"
              :preIcon="true"
            />
          </aCol>

          <aCol :span="12">
            <HayaTextField
              label="Porta (IMAP)"
              placeholder="Ex: 993"
              fieldKey="mail_client_port"
              :required="true"
              :form="form"
              :preIcon="true"
            />
          </aCol>

          <aCol class="a-center" :span="24">
            <a-button
              type="primary"
              :loading="loadingEmailForm"
              html-type="submit"
            >
              Salvar dados
            </a-button>
          </aCol>
        </aRow>
      </a-form>
    </div>
  </section>
</template>

<script>
import HayaTextField from "@/components/general/fields/HayaTextField.vue";
import HayaPasswordField from "@/components/general/fields/HayaPasswordField.vue";

export default {
  name: "EmailConfigForm",
  props: {
    user: Object,
    isFirstConfig: Boolean,
  },
  components: { HayaTextField, HayaPasswordField },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingEmailForm: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.form.setFieldsValue(this.user.meta);
      setTimeout(() => {
        this.form.setFieldsValue({
          mail_client_password: undefined,
        });
      }, 500);
    }, 500);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);
        if (!err) {
          this.loadingEmailForm = true;
          values.id = this.$store.state.userData.id;

          this.$http
            .post("/user/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$emit("onSubmitUserData");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingEmailForm = false;
            });
        } else {
          this.$message.warning("Alguns campos não foram preenchidos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.email-form-section
  background: #fafafa
  padding: 100px 0
.w-box
  margin: 0 auto
  width: 600px
  border: 1px solid #eee
  border-radius: 6px
  padding: 40px 20px 0px 20px
  background: #fff
h2
  font-size: 14px
  margin: 0 0 30px
  line-height: 1
  padding: 0 0 10px
  border-bottom: 1px solid #eee
</style>
