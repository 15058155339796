<template>
  <section class="pd-20">
    <aPageHeader class="pd-0 mb-0">
      <template #title>
        <h1>
          <img
            src="@/assets/images/dashboard/emails/emails.png"
            alt="img"
            width="40"
            class="relative top5"
          />
          E-mails
          <span class="reload-btn c-pointer" @click="getContracts()">
            <a-tooltip>
              <template slot="title"> Atualizar contratos </template>
              <img
                src="@/assets/images/contract/sync.png"
                alt="reload"
                width="14"
              />
            </a-tooltip>
          </span>
        </h1>
      </template>

      <template #extra>
        <a-button
          class="mr-5"
          shape="circle"
          icon="setting"
          @click="openEmailConfigsModal = true"
        />

        <a-button v-if="userHasEmailData()" icon="mail" type="primary">
          Escrever e-mail
        </a-button>
      </template>
    </aPageHeader>

    <aRow v-if="userHasEmailData()" type="flex" :gutter="25">
      <aCol class="sidebar" flex="256px">
        <ul>
          <li
            v-for="({ name, ico, value }, index) in sideMenu"
            :key="index"
            :class="activeMenuItem === name ? 'active' : ''"
          >
            <a-button block @click="onClickMenuItem(name, value)">
              <a-icon :type="ico" /> {{ name }}
            </a-button>
          </li>
        </ul>
      </aCol>
      <aCol class="content" flex="auto">
        <EmailMessagesTable
          ref="EmailMessagesTable"
          v-show="!openEmailId"
          @onClickOpenEmailMessage="onClickOpenEmailMessage"
        />

        <EmailMessageContent
          v-if="openEmailId"
          :id="openEmailId"
          :box="email.filters.box"
          @onClickCloseEmailContent="onClickCloseEmailContent"
        />
      </aCol>
    </aRow>

    <EmailConfigForm
      v-if="!userHasEmailData()"
      :user="user.details"
      :isFirstConfig="true"
      @onSubmitUserData="onSubmitUserData"
    />

    <a-modal
      width="600px"
      :visible="openEmailConfigsModal"
      :footer="false"
      @cancel="openEmailConfigsModal = false"
    >
      <EmailConfigForm
        v-if="openEmailConfigsModal"
        :user="user.details"
        @onSubmitUserData="onSubmitUserData"
      />
    </a-modal>
  </section>
</template>

<script>
import emailReusables from "@/components/email/mixins/emailReusables";
import userMixins from "@/mixins/user/userMixins.js";

import EmailConfigForm from "@/components/email/sections/EmailConfigForm.vue";
import EmailMessageContent from "@/components/email/sections/EmailMessageContent.vue";
import EmailMessagesTable from "@/components/email/sections/EmailMessagesTable.vue";

export default {
  name: "Emails",
  components: { EmailMessagesTable, EmailMessageContent, EmailConfigForm },
  mixins: [emailReusables, userMixins],
  data() {
    return {
      activeMenuItem: "Caixa de entrada",
      openEmailId: false,
      openEmailConfigsModal: false,
      sideMenu: [
        {
          name: "Caixa de entrada",
          ico: "inbox",
          value: "",
        },
        {
          name: "Enviados",
          ico: "mail",
          value: "Sent",
        },
        // {
        //   name: "Favoritos",
        //   ico: "star",
        // },
        {
          name: "Rascunhos",
          ico: "file",
          value: "Drafts",
        },
        {
          name: "Arquivados",
          ico: "folder",
          value: "Archive",
        },
        {
          name: "Lixeira",
          ico: "delete",
          value: "Trash",
        },
        {
          name: "Spam",
          ico: "bug",
          value: "spam",
        },
      ],
    };
  },
  mounted() {
    this.getUser(this.$store.state.userData.id);
  },
  methods: {
    onClickMenuItem(name, value) {
      this.openEmailId = false;
      this.activeMenuItem = name;
      this.email.filters.box = value;
      this.$refs.EmailMessagesTable.$emit("onClickMenuItem", name, value);
    },
    onSubmitUserData() {
      this.getUser(this.$store.state.userData.id);
      this.userHasEmailData();
    },
    onClickCloseEmailContent() {
      this.openEmailId = false;
      document.title = "Emails";
    },
    onClickOpenEmailMessage(id) {
      this.openEmailId = id;
    },
    userHasEmailData() {
      let flag = false;

      if (
        this.user.details.meta?.mail_client_email !== undefined &&
        this.user.details.meta?.mail_client_password !== undefined &&
        this.user.details.meta?.mail_client_server !== undefined &&
        this.user.details.meta?.mail_client_port !== undefined
      )
        flag = true;

      return flag;
    },
  },
};
</script>

<style lang="sass">
.emails-table
  .not-readed
    .subject, .from, .date
      color: #000
      font-weight: 600
</style>

<style lang="sass" scoped>
.content
  float: left
  width: calc(100% - 256px)
.sidebar
  float: left
  width: 256px
  ul
    list-style: none
    margin: 0
    padding: 10px 0 0
    li.active
      button
        background: #f9f9f9
        color: #be4178
        font-weight: 600
    li
      margin: 0 0 10px
      button
        text-align: left
        border: 0
        box-shadow: none
        font-size: 13px
        font-weight: 500
</style>
