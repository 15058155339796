<template>
  <section>
    <a-divider class="mb-10 mt-5" />

    <a-button
      class="mr-10"
      shape="circle"
      icon="arrow-left"
      :disabled="loadingEmailContent"
      @click="$emit('onClickCloseEmailContent')"
    />

    <a-tooltip>
      <template slot="title"> Enviar para rascunhos </template>
      <a-button
        class="mr-10"
        shape="circle"
        icon="file"
        :disabled="loadingEmailContent"
        @click="onClickMoveTo('INBOX.Drafts')"
      />
    </a-tooltip>

    <a-tooltip>
      <template slot="title"> Enviar para arquivados </template>
      <a-button
        class="mr-10"
        shape="circle"
        icon="folder"
        :disabled="loadingEmailContent"
        @click="onClickMoveTo('INBOX.Archive')"
      />
    </a-tooltip>

    <a-tooltip>
      <template slot="title"> Enviar para spam </template>
      <a-button
        class="mr-10"
        shape="circle"
        icon="bug"
        :disabled="loadingEmailContent"
        @click="onClickMoveTo('INBOX.spam')"
      />
    </a-tooltip>

    <a-tooltip>
      <template slot="title"> Enviar para lixeira </template>
      <a-button
        class="mr-10"
        shape="circle"
        icon="delete"
        :disabled="loadingEmailContent"
        @click="onClickMoveTo(record.id, 'INBOX.Trash')"
      />
    </a-tooltip>

    <a-divider class="mt-10 mb-0" />

    <div v-if="!loadingEmailContent">
      <div class="subject">{{ email.details?.email?.subject }}</div>
      <aRow class="meta" type="flex" justify="space-between">
        <aCol>
          <div class="from">
            <a-avatar class="mr-10" :size="40" icon="user" />
            {{ email.details?.email?.fromName }}
          </div>
        </aCol>
        <aCol> {{ email.details?.email?.date | formatMultiDateTime }}</aCol>
      </aRow>
      <div v-html="email.details.html ?? 'Falha ao carregar o conteúdo.'" />
    </div>

    <div class="a-center" v-if="loadingEmailContent">
      <a-spin class="f24" style="margin-top: 60px" />
    </div>
  </section>
</template>

<script>
import emailReusables from "../mixins/emailReusables";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "EmailMessageContent",
  props: {
    id: Number,
    box: String,
  },
  mixins: [emailReusables, formatThings],
  data() {
    return {
      loadingEmailContent: false,
    };
  },
  mounted() {
    this.loadingEmailContent = true;
    this.getEmailById(this.id, this.box).finally(() => {
      this.loadingEmailContent = false;
      document.title = this.email.details?.email?.subject;
    });
  },
  methods: {
    onClickMoveTo(moveTo) {
      this.loadingEmailContent = true;
      this.$http
        .get(
          `/email/move-to?id=${this.id}&folder=${moveTo}&user_id=${this.$store.state.userData.id}`
        )
        .then(() => {
          this.$emit("onClickCloseEmailContent");
        })
        .catch(({ response }) => {
          response;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.meta
  margin-bottom: 40px
  .from
    font-weight: 500
    color: #333
.subject
  padding: 20px 0
  font-size: 18px
  font-weight: 500
  color: #333
</style>
