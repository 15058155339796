export default {
  data() {
    return {
      email: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          box: "",
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      emailsTableColumns: [
        {
          title: "De",
          key: "from",
          width: 180,
          class: "from",
          scopedSlots: { customRender: "from" },
          //  sorter: true,
        },
        {
          title: "Assunto",
          key: "subject",
          width: 560,
          class: "subject",
          scopedSlots: { customRender: "subject" },
          // sorter: true,
        },

        {
          title: "Data",
          class: "date",
          width: 140,
          scopedSlots: { customRender: "date" },
          //  sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
          width: 120,
        },
      ],
    };
  },
  methods: {
    changeEmailPage(current) {
      this.email.pagination.page = current;
      this.getEmails();
    },
    emailTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.email.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.email.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getEmails();
    },
    emailChangePageSize(current, pageSize) {
      this.email.pagination.page = current;
      this.email.pagination.perPage = pageSize;
      this.getEmails();
    },
    emailFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (this.email.filters.id) {
        filters += `&id=${this.email.filters.id}`;
      }

      if (this.email.filters.box) {
        filters += `&box=${this.email.filters.box}`;
      }

      filters += `&order=${this.email.filters.order}&order-by=${this.email.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async getEmailById(id, box) {
      try {
        const data = await this.$http.get(
          `/email/details?id=${id}&box=${box}&user_id=${this.$store.state.userData.id}`
        );
        this.email.details = data.data;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.email.details = {};
      }
    },
    async getEmails(samePage) {
      this.email.loading = true;
      try {
        const { data } = await this.$http.get(
          `/email/list?page=${this.email.pagination.page}&per_page=${
            this.email.pagination.perPage
          }&user_id=${this.$store.state.userData.id}${this.emailFilters()}`
        );

        this.email.list = data.data;

        this.email.meta = data.meta;
        this.email.pagination.page = data.meta.next_page;
        this.email.pagination.total = data.meta.total;

        if (!samePage) {
          this.email.pagination.page = 1;
        }
      } catch (e) {
        console.log(e);
        this.email.list = [];
        this.email.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.email.loading = false;
      }
    },
  },
};
