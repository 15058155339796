<template>
  <section>
    <aTable
      class="travel-table emails-table"
      :columns="emailsTableColumns"
      :data-source="email.list"
      :rowClassName="rowClassName"
      :loading="email.loading"
      :pagination="false"
      :scroll="{ x: 800 }"
      :row-selection="rowSelectionCondition ?? null"
      @change="emailTableChange"
    >
      <div slot="from" slot-scope="record">
        <div class="dotted-phrase">{{ record.from }}</div>
      </div>

      <div slot="subject" slot-scope="record">
        <div class="dotted-phrase">{{ record.subject }}</div>
      </div>

      <div slot="date" slot-scope="record">
        {{ record.date | formatEmailMessageDate }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a class="edit ml-10" @click="onClickOpenEmailMessage(record.id)">
          <a-icon slot="prefix" :type="'eye-svg'" />
        </a>
      </div>
    </aTable>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="email.pagination.page"
        :page-size.sync="email.pagination.perPage"
        :total="email.pagination.total"
        @change="changeEmailPage"
        @showSizeChange="emailChangePageSize"
      >
      </a-pagination>
    </div>
  </section>
</template>

<script>
import emailReusables from "../mixins/emailReusables";

export default {
  name: "Emails",
  mixins: [emailReusables],
  data() {
    return {
      selectedRowKeys: [],
      selectedMessagesArr: [],
    };
  },
  computed: {
    rowSelectionCondition() {
      const { selectedRowKeys } = this;

      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
      };
    },
  },
  filters: {
    formatEmailMessageDate(inputDate) {
      const months = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];

      const currentDate = new Date();
      const inputDateObj = new Date(inputDate);

      const inputDay = inputDateObj.getDate();
      const inputMonth = months[inputDateObj.getMonth()];
      const inputYear = inputDateObj.getFullYear();

      if (
        inputDay === currentDate.getDate() &&
        inputDateObj.getMonth() === currentDate.getMonth() &&
        inputDateObj.getFullYear() === currentDate.getFullYear()
      ) {
        // If the input date is today, return only the hour and minute
        const hours = inputDateObj.getHours().toString().padStart(2, "0");
        const minutes = inputDateObj.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
      } else {
        // For other days, return the day and abbreviated month
        return `${inputDay} de ${inputMonth} de ${inputYear}`;
      }
    },
  },
  created() {
    this.$on("onClickMenuItem", this.onClickMenuItem);
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    onClickMenuItem(name, value) {
      console.log("onClickMenuItem");
      this.email.filters.box = value;
      this.getEmails();
    },
    rowClass(message) {
      console.log(message.isRead);
      return message.isRead;
    },
    rowClassName(record, index) {
      console.log(record, index);
      return record.isRead == 0 ? "not-readed" : "readed";
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedMessagesArr = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    onClickMoveTo(id, moveTo) {
      this.$http
        .get(
          `/email/move-to?id=${id}&folder=${moveTo}&user_id=${this.$store.state.userData.id}`
        )
        .then(() => {
          this.getEmails();
        })
        .catch(({ response }) => {
          response;
        });
    },
    onClickOpenEmailMessage(id) {
      this.$emit("onClickOpenEmailMessage", id);
    },
  },
};
</script>

<style lang="sass">
.emails-table
  .not-readed
    .subject, .from, .date
      color: #000
      font-weight: 600
</style>

<style lang="sass" scoped>
.sidebar
  float: left
  width: 256px
  ul
    list-style: none
    margin: 0
    padding: 10px 0 0
    li.active
      button
        background: #f9f9f9
        color: #be4178
        font-weight: 600
    li
      margin: 0 0 10px
      button
        text-align: left
        border: 0
        box-shadow: none
        font-size: 13px
        font-weight: 500
</style>
